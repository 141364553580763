import {
  createTheme,
  ThemeProvider,
  useMediaQuery,
  type Shadows
} from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Root } from './components'
import { InfoPage } from './components/pages/info'
import {
  AboutPage,
  NewsSources,
  PrivacyPolicy,
  TermsOfService
} from './components/pages/mobile'
import { DeleteAccount } from './components/pages/mobile/DeleteAccount'
import { NewsPage } from './components/pages/news'

interface ThemeWrapperProps {
  children: React.ReactNode
}

export const ThemeWrapper: React.FC<ThemeWrapperProps> = props => {
  const dark = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = createTheme({
    typography: {
      fontFamily: ['Manjari'].join(','),
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      mode: dark ? 'dark' : 'light',
      primary: {
        main: '#ffffff'
      },
      secondary: {
        main: '#af52de'
      },
      text: {
        primary: dark ? '#ffffff' : '#000000'
      },
      background: {
        default: dark ? '#1e1b1b' : '#fffbff',
        paper: dark ? '#282229' : '#f9f0fb'
      }
    },
    shape: {
      borderRadius: 10
    },
    shadows: Array(25).fill('none') as Shadows
  })

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <NewsPage />
      },
      {
        path: '/info',
        element: <InfoPage />
      }
    ]
  },
  {
    path: '/about',
    element: <AboutPage />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />
  },
  {
    path: '/news-sources',
    element: <NewsSources />
  },
  {
    path: '/delete-account',
    element: <DeleteAccount />
  },
  {
    path: '*',
    element: <Navigate to="/" replace={true} />
  }
])

ReactDOM.render(
  <React.StrictMode>
    <ThemeWrapper>
      <RouterProvider router={router} />
    </ThemeWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)
