import EmailIcon from '@mui/icons-material/Email'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PhoneIcon from '@mui/icons-material/Phone'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import React from 'react'
import { useImmer } from 'use-immer'
import AboutWennowContent from '../../../content/AboutWennow'
import PrivacyPolicyContent from '../../../content/PrivacyPolicy'
import TermsOfServiceContent from '../../../content/TermsOfService'

export const InfoPage: React.FC = () => {
  const theme = useTheme()
  const [state, updateState] = useImmer({
    emailTooltipOpen: false,
    phoneTooltipOpen: false
  })

  return (
    <>
      <Card style={{ marginBottom: '25px' }}>
        <CardContent>
          <Typography variant="h5">About Wennow</Typography>
          <div style={{ textAlign: 'center' }}>{AboutWennowContent}</div>
        </CardContent>
      </Card>
      <Card style={{ marginBottom: '25px' }}>
        <CardContent>
          <Typography variant="h5">Contact us</Typography>
          <List disablePadding={true}>
            <ListItem
              secondaryAction={
                <ListItemButton
                  style={{ borderRadius: theme.shape.borderRadius }}
                  onClick={() => {
                    void navigator.clipboard.writeText('support@wennow.io')
                    updateState({ ...state, emailTooltipOpen: true })
                    void new Promise(resolve => setTimeout(resolve, 3000)).then(() => { updateState({ ...state, emailTooltipOpen: false }) })
                  }}
                >
                  <Tooltip
                    title="Copied!"
                    placement="top"
                    open={state.emailTooltipOpen}
                  >
                    <Typography>support@wennow.io</Typography>
                  </Tooltip>
                </ListItemButton>
              }
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="Email" />
            </ListItem>
            <ListItem
              secondaryAction={
                <ListItemButton
                  style={{ borderRadius: theme.shape.borderRadius }}
                  onClick={() => {
                    void navigator.clipboard.writeText('(425) 998-6706')
                    updateState({ ...state, phoneTooltipOpen: true })
                    void new Promise(resolve => setTimeout(resolve, 3000)).then(() => { updateState({ ...state, phoneTooltipOpen: false }) })
                  }}
                >
                  <Tooltip
                    title="Copied!"
                    placement="top"
                    open={state.phoneTooltipOpen}
                  >
                    <Typography>(425) 998-6706</Typography>
                  </Tooltip>
                </ListItemButton>
              }
            >
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary="Phone" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <div style={{ paddingBottom: '25px' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Privacy Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>{PrivacyPolicyContent}</AccordionDetails>
        </Accordion>
      </div>
      <div style={{ paddingBottom: '25px' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Terms of Service</Typography>
          </AccordionSummary>
          <AccordionDetails>{TermsOfServiceContent}</AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}
