import { Typography } from '@mui/material'
import React from 'react'

const TermsOfServiceContent = (
  <>
    <Typography variant="h6">1. Terms</Typography>
    <br />
    <Typography>
      By accessing this web site, you are agreeing to be bound by these web site
      Terms and Conditions of Use, all applicable laws and regulations, and
      agree that you are responsible for compliance with any applicable local
      laws. If you do not agree with any of these terms, you are prohibited from
      using or accessing this site. The materials contained in this web site are
      protected by applicable copyright and trade mark law.
    </Typography>
    <br />
    <Typography variant="h6">2. Use License</Typography>
    <br />
    <Typography>
      <ol type="a">
        <li>
          Permission is granted to temporarily download one copy of the
          materials (information or software) on Wennow&apos;s web site for
          personal, non-commercial transitory viewing only. This is the grant of
          a license, not a transfer of title, and under this license you may
          not:
          <ol type="i">
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on
              Wennow&apos;s web site;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transfer the materials to another person or &apos;mirror&apos; the
              materials on any other server.
            </li>
          </ol>
        </li>
        <li>
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by Wennow at any time. Upon
          terminating your viewing of these materials or upon the termination of
          this license, you must destroy any downloaded materials in your
          possession whether in electronic or printed format.
        </li>
      </ol>
    </Typography>
    <Typography variant="h6">3. Disclaimer</Typography>
    <br />
    <ol type="a">
      <li>
        The materials on Wennow&apos;s web site are provided &apos;as is&apos;.
        Wennow makes no warranties, expressed or implied, and hereby disclaims
        and negates all other warranties, including without limitation, implied
        warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property or other violation
        of rights. Further, Wennow does not warrant or make any representations
        concerning the accuracy, likely results, or reliability of the use of
        the materials on its Internet web site or otherwise relating to such
        materials or on any sites linked to this site.
      </li>
    </ol>
    <Typography variant="h6">4. Limitations</Typography>
    <br />
    <Typography>
      In no event shall Wennow or its suppliers be liable for any damages
      (including, without limitation, damages for loss of data or profit, or due
      to business interruption,) arising out of the use or inability to use the
      materials on Wennow&apos;s Internet site, even if Wennow or a Wennow
      authorized representative has been notified orally or in writing of the
      possibility of such damage. Because some jurisdictions do not allow
      limitations on implied warranties, or limitations of liability for
      consequential or incidental damages, these limitations may not apply to
      you.
    </Typography>
    <br />
    <Typography variant="h6">5. Revisions and Errata</Typography>
    <br />
    <Typography>
      The materials appearing on Wennow&apos;s web site could include technical,
      typographical, or photographic errors. Wennow does not warrant that any of
      the materials on its web site are accurate, complete, or current. Wennow
      may make changes to the materials contained on its web site at any time
      without notice. Wennow does not, however, make any commitment to update
      the materials.
    </Typography>
    <br />
    <Typography variant="h6">6. Links</Typography>
    <br />
    <Typography>
      Wennow has not reviewed all of the sites linked to its Internet web site
      and is not responsible for the contents of any such linked site. The
      inclusion of any link does not imply endorsement by Wennow of the site.
      Use of any such linked web site is at the user&apos;s own risk.
    </Typography>
    <br />
    <Typography variant="h6">7. Site Terms of Use Modifications</Typography>
    <br />
    <Typography>
      Wennow may revise these terms of use for its web site at any time without
      notice. By using this web site you are agreeing to be bound by the then
      current version of these Terms and Conditions of Use.
    </Typography>
    <br />
    <Typography variant="h6">8. Governing Law</Typography>
    <br />
    <Typography>
      Any claim relating to Wennow&apos;s web site shall be governed by the laws
      of the State of Washington without regard to its conflict of law
      provisions.
    </Typography>
    <br />
    <Typography>
      General Terms and Conditions applicable to Use of a Web Site.
    </Typography>
    <br />
  </>
)

export default TermsOfServiceContent
