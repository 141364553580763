import { Card, CssBaseline, Link, Typography } from '@mui/material'
import React from 'react'

export const NewsSources: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Card style={{ margin: '10px', padding: '20px' }}>
        <Typography variant="h4">News sources of Wennow</Typography>
        <Typography>
          Wennow works by aggregating news articles from various sources. The
          sources, along with their contact information, can be found below.
        </Typography>
        <Typography>
          All news articles published by outside news sources are NOT the
          property of Wennow. Articles published by outside sources will link
          directly to the original source&apos;s website. All rights go to the
          original publisher.
        </Typography>
        <Typography>
          <ul>
            {[
              {
                href: 'https://www.ap.org/contact-us/',
                name: 'Associated Press',
                email: 'info@ap.org',
                phone: '844 777 2006'
              },
              {
                href: 'https://www.bbc.co.uk/contact',
                name: 'BBC',
                email: 'newswatch@bbc.co.uk',
                phone: '0370 010 6676'
              },
              {
                href: 'https://www.breitbart.com/contact-us/',
                name: 'Breitbart',
                email: 'sales@breitbart.com',
                phone: '310 508 0220'
              },
              {
                href: 'https://help.cnn.com/us/ContactUs/Topic/Getting_Started',
                name: 'CNN',
                email: 'help@cnn.com',
                phone: '404 827 1500'
              },
              {
                href: 'https://www.foxnews.com/contact',
                name: 'FOX News',
                email: 'FoxNationCustomerCare@fox.com',
                phone: '888 369 4762'
              },
              {
                href: 'https://help.npr.org/contact/s/',
                name: 'NPR',
                email: 'plus@npr.org',
                phone: '202 513 2000'
              }
            ]
              .sort((s1, s2) => s1.name.localeCompare(s2.name))
              .map((s, i) => {
                return (
                  <>
                    <Link href={s.href}>{s.name}</Link>
                    <ul>
                      <li>{'Email: ' + s.email}</li>
                      <li>{'Phone: ' + s.phone}</li>
                    </ul>
                  </>
                )
              })}
          </ul>
        </Typography>
      </Card>
    </>
  )
}
