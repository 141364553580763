import { Card, CssBaseline, Typography } from '@mui/material'
import React from 'react'
import TermsOfServiceContent from '../../../content/TermsOfService'

export const TermsOfService: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Card style={{ margin: '10px', padding: '20px' }}>
        <Typography variant="h4">Terms of Service of Wennow</Typography>
        <br />
        {TermsOfServiceContent}
      </Card>
    </>
  )
}
