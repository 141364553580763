import { Grid, Stack, Typography } from '@mui/material'
import * as React from 'react'
import appStore from '../../../assets/app_store.svg'
import googlePlay from '../../../assets/google_play.png'
import promo1 from '../../../assets/promo_1.png'
import promo2 from '../../../assets/promo_2.png'
import promo3 from '../../../assets/promo_3.png'
import promo4 from '../../../assets/promo_4.png'

export const NewsPage: React.FC = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Typography>
        Download Wennow today to browse news, create a customized feed, and
        leave your reactions for the world to see!
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <a href="https://apps.apple.com/us/app/wennow/id1615701377">
          <img src={appStore} style={{ height: '50px' }} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=wennow.io">
          <img src={googlePlay} style={{ height: '72px' }} />
        </a>
      </Stack>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={1}
        style={{ paddingBottom: '25px' }}
      >
        {[promo1, promo2, promo3, promo4].map((image, i) => {
          return <Grid key={i}>
            <img src={image} style={{ width: '350px', borderRadius: '25px' }} />
          </Grid>
        })}
      </Grid>
    </Stack>
  )
}
