import FacebookIcon from '@mui/icons-material/Facebook'
import InfoIcon from '@mui/icons-material/Info'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Button, Fab, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import * as React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/wennow_purple.png'

export const Root: React.FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <>
      <CssBaseline />
      <Stack
        direction="column"
        justifyContent="center"
        marginTop={1.5}
        marginLeft={3}
        marginRight={3}
      >
        <Grid container alignItems="center" marginBottom={5}>
          <Grid xs={12} sm={3} style={{ textAlign: 'center' }}>
            <img src={logo} style={{ width: '125px' }} />
          </Grid>
          <Grid xs={12} sm={6} style={{ textAlign: 'center' }}>
            <Button onClick={() => { navigate('/') }}>
              <Typography variant="h3" style={{ color: theme.palette.text.primary }}>Wennow</Typography>
            </Button>
          </Grid>
          <Grid xs={12} sm={3} style={{ textAlign: 'center' }}>
            {[
              {
                icon: <FacebookIcon style={{ fontSize: '35px' }} />,
                link: 'https://www.facebook.com/Wennow-109979891647402'
              },
              {
                icon: <InstagramIcon style={{ fontSize: '35px' }} />,
                link: 'https://www.instagram.com/wennow.io/'
              },
              {
                icon: <LinkedInIcon style={{ fontSize: '35px' }} />,
                link: 'https://www.linkedin.com/company/wennow/'
              }
            ].map((social, index) => {
              return (
                <IconButton
                  key={index}
                  onClick={() => window.open(social.link, '_blank')}
                >
                  {social.icon}
                </IconButton>
              )
            })}
          </Grid>
        </Grid>
        <Outlet />
        <Fab
          size="large"
          color="secondary"
          variant="extended"
          onClick={() => { navigate('/info') }}
          style={{
            margin: 0,
            top: 'auto',
            left: 'auto',
            bottom: 20,
            right: 20,
            position: 'fixed'
          }}
        >
          <InfoIcon sx={{ mr: 1 }} />
          About Wennow
        </Fab>
        <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>
          © {new Date().getFullYear()} Wennow, LLC
        </Typography>
      </Stack>
    </>
  )
}
