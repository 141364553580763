import { Card, CssBaseline, Typography } from '@mui/material'
import React from 'react'

export const DeleteAccount: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Card style={{ margin: '10px', padding: '20px' }}>
        <Typography variant="h4">Delete account</Typography>
        <br />
        <Typography>
          We want to thank you for using Wennow. Hopefully this isn&apos;t our
          last time seeing each other, but we really value the time you spent
          with us.
        </Typography>
        <br />
        <Typography>
          If you would like to delete your account, please login to the app,
          navigate to the profile tab, scroll to the bottom and select
          &apos;Delete account&apos;.
        </Typography>
        <br />
        <Typography>
          Alternitavely, you can email support@wennow.io and request that we
          delete your account on your behalf.
        </Typography>
        <br />
        <Typography>
          Once your account is deleted, all data associated with your account
          will be removed. This is non-reversible and your account data will
          gone forever.
        </Typography>
      </Card>
    </>
  )
}
