import { Typography } from '@mui/material'
import React from 'react'

const AboutWennowContent = (
  <>
    <Typography>
      Wennow is a platform to see what the world thinks about internet content.
      We promote what people are interacting with, not what advertisers want you
      to see.
    </Typography>
    <br />
    <Typography>
      We use advanced machine learning to detect the sentiment of content so
      that you can quickly sift through negativity.
    </Typography>
    <br />
    <Typography>
      We let you determine what shows up in your feeds. We don&apos;t ask for
      your personal information because we don&apos;t need it. We just ask for
      your email to verify real humans are using the platform.
    </Typography>
    <br />
    <Typography>
      We will always try our best to make sure that Wennow is up and running.
      When issues do arrise we will try to fix them ASAP.
    </Typography>
    <br />
    <Typography>Thank you for using Wennow!</Typography>
  </>
)

export default AboutWennowContent
