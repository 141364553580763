import { Card, CssBaseline, Typography } from '@mui/material'
import React from 'react'
import logo from '../../../assets/wennow_purple.png'
import AboutWennowContent from '../../../content/AboutWennow'

export const AboutPage: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Card style={{ margin: '10px', padding: '20px' }}>
        <img
          src={logo}
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '20px',
            marginBottom: '20px',
            maxWidth: '40%'
          }}
        />
        <Typography variant="h4">About Wennow</Typography>
        <br />
        {AboutWennowContent}
      </Card>
    </>
  )
}
