import { Card, CssBaseline, Typography } from '@mui/material'
import React from 'react'
import PrivacyPolicyContent from '../../../content/PrivacyPolicy'

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Card style={{ margin: '10px', padding: '20px' }}>
        <Typography variant="h4">Privacy Policy of Wennow</Typography>
        <br />
        {PrivacyPolicyContent}
      </Card>
    </>
  )
}
